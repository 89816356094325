<template>
  <div>
    <s-dialog :open="true" size="md" to="stds-dialog-multilingual-settings">
      <s-dialog-overlay />
      <s-dialog-panel class="!w-[93.2rem]">
        <st-dialog-header @clickClose="onClose">
          <span class="!font-bold">{{ $t('studio.ai_translation.title1_settings') }}</span>
        </st-dialog-header>
        <s-dialog-content>
          <s-dialog-content-body>
            <div v-show="!isLoading">
              <p class="text-sm leading-md text-on-surface-elevation-3 -mt-4">
                <safe-html :html="$t('studio.ai_translation.guide1')" />
              </p>
              <div class="flex items-end mt-12 mb-12">
                <div class="w-[calc(50%+2.4rem)]">
                  <div class="flex items-center pr-48">
                    <p class="text-md font-medium leading-sm text-on-surface-elevation-2">
                      {{ $t('studio.ai_translation.base_lang') }}
                    </p>
                    <span
                      class="ml-8 rounded-full bg-[#ecf0f3] px-[.6rem] h-[2.2rem] flex items-center text-2xs font-medium leading-xs text-gray500"
                    >
                      {{ $t(language) }}
                    </span>
                    <s-tooltip
                      arrow
                      :content="$t('studio.group.home.language_tool_tip')"
                      :duration="0"
                      :distance="4"
                      useFlip
                      flipOnUpdate
                      placement="bottom"
                      trigger="mouseenter focus"
                      :theme="'studio-tooltip'"
                      :zIndex="2501"
                      :allowHTML="true"
                    >
                      <template #target>
                        <s-icon
                          icon="ic-v2-state-info-circle-line"
                          size="xl"
                          class="text-on-surface-elevation-4 flex ml-4"
                        />
                      </template>
                    </s-tooltip>
                  </div>
                  <slot name="origin-content"></slot>
                </div>
                <div class="w-[calc(50%-2.4rem)]">
                  <s-tabs
                    v-model="model"
                    variant="line-fixed"
                    size="sm"
                    class="stove-studio-tab-line"
                    @update:modelValue="updateCurrentTab"
                  >
                    <s-tab-list>
                      <s-tab-item
                        v-for="lang in otherLanguages"
                        :key="lang.langCode"
                        :value="lang.langCode"
                      >
                        <span class="break-words">{{ $t(getTabName(lang.langCode)) }}</span><s-icon v-if="lang.hasError" size="xl" icon="ic-v2-state-warning-circle-fill" class="text-error ml-2" />
                      </s-tab-item>
                    </s-tab-list>
                    <s-tab-panels>
                      <slot name="translated-content"></slot>
                    </s-tab-panels>
                  </s-tabs>
                </div>
              </div>
            </div>
            <div
              v-show="isLoading"
              class="flex flex-col justify-center items-center gap-20 pb-24"
              :style="{ height: loadingHeight }"
            >
              <s-circular-progress size="lg" />
              <p class="text-center text-lg font-medium leading-lg text-on-surface-elevation-3">
                <safe-html :html="$t('studio.ai_translation.progress_wait_msg')" />
              </p>
            </div>
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button
            variant="outline"
            :isDisabled="!isProductWritable || isDisabled"
            icon="ic-v2-communication-translate-line"
            @click="translateAllField"
          >
            {{ $t('studio.ai_translation.all_btn') }}
          </s-button>
          <s-button :isDisabled="!isProductWritable || isDisabled" @click="confirm">
            {{ $t('studio.common.popup_case_save_btn') }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target name="stds-dialog-multilingual-settings" />
  </div>
</template>

<script setup lang="ts">
import { isEqual } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, defineModel, nextTick, onMounted, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import SafeHtml from '@/components/common/safe-html.vue';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import { showConfirm } from '@/composables/useDialog';
import { TRANSLATE_LANGUAGES_SETTINGS } from '@/constants/common.const';
import useProductStore from '@/stores/product.store';
import { useUserStore } from '@/stores/user.store';
import type { LanguageModel, LanguageTabItem, SettingLanguage } from '@/types/common/common.type';
import { isEmptyTranslated } from '@/utils/product.util';

const props = defineProps<{
  settings?: SettingLanguage[];
  isLoading?: boolean;
  otherLanguages?: LanguageTabItem[];
  currentTab: string;
  isDisabled?: boolean;
  onTranslateAll?:() => void;
}>();

const productStore = useProductStore();

const { isProductWritable } = storeToRefs(productStore);

const userStore = useUserStore();
const { selectedGroupInfo } = storeToRefs(userStore);

const { settings, isLoading } = toRefs(props);

const model = defineModel<string>({ required: true });

const { t } = useI18n();

const originalData = ref<SettingLanguage[]>(settings.value ?? []);

const isChanged = computed<boolean>(() => {
  return !isEqual(originalData.value, settings.value);
});

const language = computed(() => {
  return (
    TRANSLATE_LANGUAGES_SETTINGS.find(
      (lang: LanguageModel) => lang.langCode === selectedGroupInfo.value?.languageCd
    )?.langTitle || ''
  );
});

const emits = defineEmits<{
  close: [void];
  clickTranslateAll: [v: void];
  save: [v: void];
  updateCurrentTab: [v: string];
}>();

const updateCurrentTab = (value: string) => {
  emits('updateCurrentTab', value);
};

const onClose = async () => {
  if (isLoading.value) {
    return;
  }

  if (!isChanged.value) {
    emits('close');
    return;
  }
  emits('close');
};

const translateAllField = async () => {
  if (props.onTranslateAll && typeof props.onTranslateAll === 'function') {
    props.onTranslateAll();
    return;
  }
  if (settings.value && isEmptyTranslated(settings.value)) {
    emits('clickTranslateAll');
    return;
  }

  const confirm = await showConfirm({
    content: t('studio.common.popup_case_ai_overwrite'),
    confirmLabel: t('studio.common.popup_case_translation_in_progress_btn'),
    cancelLabel: t('studio.common.popup_case_cancel_btn'),
    cancelVariant: 'outline'
  });
  if (!confirm) {
    return;
  }
  emits('clickTranslateAll');
};

const confirm = async () => {
  emits('save');
};

const loadingHeight = ref<string>('auto');

const getHeight = () => {
  loadingHeight.value = `${
    document.querySelector('.stds-dialog-content-body')?.clientHeight ?? 0
  }px`;
};

const getTabName = (lang: string) => {
  return `studio.ai_translation.base_lang_${lang.toLowerCase().replace('-', '_')}`;
};

watch(
  () => props.currentTab,
  () => {
    model.value = props.currentTab;
  }
);

onMounted(() => {
  nextTick(() => {
    getHeight();
  });
});
</script>
